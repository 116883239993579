<template>
   <div class="layout-px-spacing" v-if="this.$can('admin')">
      <breadcrumb />

      <div class="row layout-top-spacing">
         <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
            <div class="panel p-0">
               <div class="custom-table table3">

                  <div class="table-header">
                     <h3>Používatelia</h3>

                     <div class="header-search">
                        <router-link to="/pouzivatelia/new" v-b-tooltip.hover title="Vytvoriť nového používateľa">

                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-plus-circle">
                              <circle cx="12" cy="12" r="10"></circle>
                              <line x1="12" y1="8" x2="12" y2="16"></line>
                              <line x1="8" y1="12" x2="16" y2="12"></line>
                           </svg>
                        </router-link>
                        <input type="text" v-model.trim="search_value" placeholder="Search..."
                               class="form-control form-control-sm"
                               id="__BVID__321">
                        <div class="search-image">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-search">
                              <circle cx="11" cy="11" r="8"></circle>
                              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                           </svg>
                        </div>
                     </div>
                  </div>

                  <div class="table-responsive">
                     <table class="table table-hover">
                        <thead>
                           <tr>
                              <th>Meno</th>
                              <th>Email</th>
                              <th>Mesto</th>
                              <th>Rola</th>
                              <th class="actions text-center">Akcie</th>
                           </tr>
                        </thead>
                        <tbody is="transition-group">
                           <tr v-if="users.length === 0" key="empty">
                              <td colspan="6" class="text-center">Ziadni používatelia</td>
                           </tr>
                           <tr v-for="user in users" :key="user.id">
                              <td tabindex="0" class="">
                                 <!--                                            <div class="d-flex">-->
                                 <!--                                                <router-link :to="'/pouzivatelia/' + user.id" class="usr-img-frame mr-3 rounded-circle">-->
                                 <!--                                                    <img v-if="user.images" :src="user.images.original_url" class="img-fluid rounded-circle" alt="avatar">-->
                                 <!--                                                    <svg v-if="!user.images" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user placeholder" data-v-5522efca=""><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>-->
                                 <!--                                                </router-link>-->
                                 <!--                                                <router-link :to="'/pouzivatelia/' + user.id" class="align-self-center mb-0 admin-name">{{ user.name }}</router-link>-->
                                 <!--                                            </div>-->
                                 <div class="d-flex">
                                    <div class="usr-img-frame mr-3 rounded-circle">
                                       <!--                                                    <div class="active-status"></div>-->
                                       <img v-if="user.images" :src="user.images.original_url" class="img-fluid rounded-circle"
                                            alt="avatar">
                                       <svg v-if="!user.images" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-user placeholder"
                                            data-v-5522efca="">
                                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                          <circle cx="12" cy="7" r="4"></circle>
                                       </svg>
                                    </div>
                                    <div class="align-self-center mb-0 admin-name">{{ user.name }}</div>
                                 </div>
                              </td>
                              <td>{{ user.email }}</td>
                              <td>{{ user.town ? user.town.town_name : "-" }}</td>
                              <td>{{ user.role ? user.role.name : "-" }}</td>
                              <td class="action-btn text-center">
                                 <router-link :to="'/pouzivatelia/' + user.id + '/edit'" class="mr-1" v-b-tooltip.hover
                                              title="Editovať">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="feather feather-edit-2 edit"
                                    >
                                       <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                    </svg>
                                 </router-link>
                                 <a href="javascript:;" @click="delete_user(user.id)" v-b-tooltip.hover title="Vymazať">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="feather feather-trash-2"
                                    >
                                       <polyline points="3 6 5 6 21 6"></polyline>
                                       <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                       <line x1="10" y1="11" x2="10" y2="17"></line>
                                       <line x1="14" y1="11" x2="14" y2="17"></line>
                                    </svg>
                                 </a>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                     <!--                          <div class="loading-row" v-if="loading">-->
                     <!--                          </div>-->
                  </div>

                  <div class="table-footer">
                     <div v-if="users">Zobrazuje se {{ users.length }} z {{ meta?.total }}</div>
                     <div class="paginating-container pagination-solid flex-column align-users-right">
                        <b-pagination
                          v-if="users"
                          prev-text="Prev"
                          next-text="Next"
                          first-text="First"
                          last-text="Last"
                          first-class="first"
                          prev-class="prev"
                          next-class="next"
                          last-class="last"
                          class="rounded"
                          :total-rows="meta?.total"
                          :per-page="meta?.per_page"
                          @change="handlePageChange"
                        >
                           <template #first-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"
                                   stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                       d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
                              </svg>
                           </template>
                           <template #prev-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"
                                   stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                              </svg>
                           </template>
                           <template #next-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"
                                   stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                              </svg>
                           </template>
                           <template #last-text>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"
                                   stroke="currentColor">
                                 <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                       d="M13 5l7 7-7 7M5 5l7 7-7 7" />
                              </svg>
                           </template>
                        </b-pagination>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

   </div>
   <div class="layout-px-spacing d-flex align-items-center justify-content-center" v-else>
      <div class="row layout-top-spacing">
         <div class="col-12">
            <h4 class="text-center px-3">Nemáte oprávnění zobrazit tuto stránku.</h4>
         </div>
      </div>
   </div>
</template>

<script>
   import "../../assets/sass/global/table.scss";
   import _debounce from "lodash/debounce";

   export default {
      data() {
         return {
            per_page: 10,
            search_value: "",
            page: 1,
            options: [
               { value: "5", text: "5" },
               { value: "10", text: "10" },
               { value: "20", text: "20" },
               { value: "50", text: "50" }
            ]
         };
      },
      watch: {
         search_value: {
            handler: _debounce(function() {
               this.fetchData();
            }, 250),
            immediate: false
         },
         per_page() {
            if (this.per_page > this.meta.total) {
               this.page = 1;
            }
            this.fetchData();
         }
      },
      created() {
         this.fetchData();
      },
      methods: {
         fetchData() {
            this.$store.dispatch("user/getUsers", {
               page: this.page,
               per_page: this.per_page,
               search_value: this.search_value
            });
         },
         handlePageChange(value) {
            this.page = value;
            this.fetchData();
         },
         delete_user(user) {
            if (window.confirm("Naozaj chceš vymazať používateľa " + user + "?\n\nOdstránením uživateľa sa neovplyvnia žiadne objednávky.")) {
               this.$axios.delete(`users/${user}`)
                 .then((response) => {
                    this.$store.dispatch("user/getUsers");
                    this.$toast.success(response.data.message);
                 })
                 .catch(error => {
                    console.log(error);
                    /*this.$toast.error(error.data.error);*/
                 });
            }
         }
      },
      computed: {
         users() {
            return this.$store.state.user.users;
         }, meta() {
            return this.$store.state.user.users_meta;
         }
      }
   };
</script>

<!--<style lang="scss">-->
<!--  @-webkit-keyframes moving-gradient {-->
<!--    0% { background-position: -250px 0; }-->
<!--    100% { background-position: 250px 0; }-->
<!--  }-->

<!--  .loading-row {-->

<!--      width: 100%;-->
<!--      height: 50px;-->
<!--      background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);-->
<!--      background-size: 500px 100px;-->
<!--      animation-name: moving-gradient;-->
<!--      animation-duration: 1s;-->
<!--      animation-iteration-count: infinite;-->
<!--      animation-timing-function: linear;-->
<!--      animation-fill-mode: forwards;-->

<!--  }-->
<!--</style>-->

<style scoped>
   .table {
      white-space: nowrap;
   }

   .active-status {
      content: "";
      position: absolute;
      bottom: 7%;
      right: -4%;
      width: 30%;
      height: 30%;
      border-radius: 50%;
      border: 2px solid #fff;
      background-color: #1abc9c;
   }

   .usr-img-frame {
      position: relative;
   }


   .table-header h3 {
      margin-bottom: 0.6rem;

      @media (max-width: 576px) {
         margin-bottom: 1.2rem;
      }
   }
</style>
